import * as actionType from "../../actions/@movie-analyzer/actionType";

const initialState = {

  synthetic_state : "OFF",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionType.ON_SYNTHETIC_STATE:

      return {
        ...state,
        synthetic_state: "ON"
      };

    case actionType.OFF_SYNTHETIC_STATE:

      return {
        ...state,
        synthetic_state: "OFF"
      };

    default:
      return state;
  }
};

export default reducer;
