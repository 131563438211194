import * as ApiService from './apiService'

export async function getPendingMovies() {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/pending-movies';
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function getAllMovies(start,end) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = true;
  apiObject.endPoint = '/movies2';
  apiObject.body = {start:start,end:end};
  return await ApiService.callApi(apiObject);
}

export async function postInitialAdd(body) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = true;
  apiObject.endPoint = '/inital_add';
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}

export async function submitMovie(body) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = true;
  apiObject.endPoint = '/submit_job';
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}

export async function getMovieDetail(id) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/movie/'+id;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function login(body) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = false;
  apiObject.endPoint = '/token';
  apiObject.body = body;
  return await ApiService.login(apiObject);
}

export async function getUserDetails() {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/users/me/';
  apiObject.body = null;
  return await ApiService.callApix(apiObject);
}

export async function getCasterDetail(id) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/cast/'+id;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function getCrewList(data) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/cast/query/'+data;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function getMovieList(data) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/movie/query/'+data;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function getCastAndCrewByMovieId(id) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/movie/initial_data/'+id;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function editMovieDetails(data) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = true;
  apiObject.endPoint = '/what_if_analysis';
  apiObject.body = data;
  return await ApiService.callApi(apiObject);
}

export async function submitSyntheticMovie(body) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = true;
  apiObject.endPoint = '/submit_synthetic_job';
  apiObject.body = body;
  return await ApiService.callApi(apiObject);
}

export async function isMovieAvailable(id) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/movie_available/'+id;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function cancelPendingMovies(id) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = true;
  apiObject.endPoint = '/remove_job?job_id='+id;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function filterSystemSimilarMovies(id, name, year) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/matching_movies?imdb_id='+id+'&name='+name+'&year='+year;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function checkSubmissionAvailability() {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/sub_allowed_status';
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function getCastAndCrewByMovieIdWikipedia(id) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = true;
  apiObject.endPoint = '/movie/initial_wiki';
  apiObject.body = {url:id};
  return await ApiService.callApi(apiObject);
}

export async function getToxicity(id) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/toxicity_chart/'+id;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function getGRP(id) {
  const apiObject = {};
  apiObject.method = 'get';
  apiObject.authentication = true;
  apiObject.endPoint = '/all_grp/'+id;
  apiObject.body = {};
  return await ApiService.callApi(apiObject);
}

export async function search(text) {
  const apiObject = {};
  apiObject.method = 'post';
  apiObject.authentication = true;
  apiObject.endPoint = '/search';
  apiObject.body = {txt:text};
  return await ApiService.callApi(apiObject);
}

export async function testFunction() {
  return 'this is test';
}
