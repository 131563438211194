import * as actionType from '../../actions/@movie-analyzer/actionType';

const initialState = {
  request_body: null,
  crew_data: null,
  // request_body:{
  //   movie: {
  //     is_dubbed: 0,
  //     language: 'English',
  //     title: 'Project Power',
  //     year: '2020'
  //   },
  //   original: {
  //     directors: "",
  //     genres: "",
  //     language: "",
  //     producers: "",
  //     title: "",
  //     top_cast:"",
  //     year: ""
  //   },
  //   url_full_movie: "",
  //   url_password: ""
  // }

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STORE_REQUEST_BODY:
      return {
        ...state,
        request_body: action.value
      };
    case actionType.REMOVE_REQUEST_BODY:
      return {
        ...state,
        request_body:null,
        crew_data:null
      };
    case actionType.STORE_CREW_DATA:
      return {
        ...state,
        crew_data:action.value
      };
    default:
      return state;
  }
};

export default reducer;
