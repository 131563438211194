import React,{Component} from 'react';
import { Button, ButtonGroup, Dropdown} from 'react-bootstrap';
import * as Icon from "react-feather"

import './split-btn-style.scss';
import * as api_actions from "../../../../service/movie-analyzer-service";
import * as ma_toasts from "../../../../utility/@movie-analyzer/notifictions/toast";

class SplitButton extends Component{

  checkSubmissionAvailability = () => {
    let availability = api_actions.checkSubmissionAvailability();
    availability.then(res=>{
      if(res.data.allowed===1) {
        this.handleMovieSubmission();
      } else {
        ma_toasts.maErrorNotifyCall(res.data.message===""?'Something went wrong':res.data.message, 0);
      }
    }).catch(err=>{
      ma_toasts.maErrorNotifyCall('Something went wrong', 0);
    });
  };

  handleMovieSubmission = () => {
    this.props.function("movie")
  };

  render() {
    return (
      <div className="ui compact menu split-btn">
        <div className="ui simple dropdown item split-btn-background">
          Submit a Movie
          <i className="dropdown icon"></i>
          <div className="menu">
            <div className="item" style={{color: '#F24257'}} onClick={this.checkSubmissionAvailability}>Full Movie</div>
            <div className="item" style={{color: '#F24257'}} onClick={()=>this.props.function("trailer")}>Long Trailer <span style={{color:"red"}}> (**beta) </span></div>
            <div className="item" style={{color: '#F24257'}} onClick={()=>this.props.function("synthetic")}>Synthetic Movie</div>

          </div>
        </div>
      </div>

    );
  }

}

export default SplitButton;
