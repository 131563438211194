import * as actionTypes from './actionType';

export const onSyntheticMovieState=(data)=>{
  return {
    type: actionTypes.ON_SYNTHETIC_STATE,
    value: data
  }
};

export const offSyntheticMovieState=()=>{
  return {
    type: actionTypes.OFF_SYNTHETIC_STATE,
    value: null
  }
};
