import * as actionTypes from './actionType';

export const storeSyntheticMovieDetails=(data)=>{
  return {
    type: actionTypes.SET_SYNTHETIC_MOVIE_DETAILS,
    value: data
  }
};

export const storeNewSimilarMovieDetail=(data)=>{
  return {
    type: actionTypes.STORE_NEW_SIMILAR_MOVIE,
    value: data
  }
};
