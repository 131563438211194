import * as actionTypes from './actionType';

export const storeMovieDetails=(data)=>{
  return {
    type: actionTypes.SET_OUT_MOVIE_DETAILS,
    value: data
  }
};

export const removeMovieDetails=()=>{
  return {
    type: actionTypes.REMOVE_MOVIE_DETAILS,
    value: null
  }
};

export const updateMovieDetails=(data)=>{
  return{
    type: actionTypes.UPDATE_MOVIE_DETAILS,
    value: data
  }
}
