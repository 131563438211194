import * as actionType from "../../actions/@movie-analyzer/actionType";

const initialState = {

  synthetic_data : null,
  similar_movie : null,

};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionType.SET_SYNTHETIC_MOVIE_DETAILS:
      return {
        ...state,
        synthetic_data: action.value
      };

    case actionType.STORE_NEW_SIMILAR_MOVIE:
      return {
        ...state,
        similar_movie: action.value
      };

    default:
      return state;
  }
};

export default reducer;
