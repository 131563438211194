import * as actionType from '../../actions/@movie-analyzer/actionType';

const initialState = {
  userDetails: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STORE_USER:
      return {
        ...state,
        userDetails: action.value
      };
    case actionType.REMOVE_USER:
      return {
        ...state,
        userDetails:null
      };
    default:
      return state;
  }
};

export default reducer;

