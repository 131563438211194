import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import initialAddReducer from "./@movie-analyzer/initial_add_reducer";
import verifyMovieReducer from "./@movie-analyzer/verify_movie_reducer";
import userReducer from "./@movie-analyzer/user_reducer";
import movieStateReducer from './@movie-analyzer/movie_state_reducer';
import movieDetailsReducer from './@movie-analyzer/movie_detail_reducer';
import castDetailsReducer from './@movie-analyzer/cast_detail_reducer';
import spinnerReducer from './@movie-analyzer/spinner_reducer';
import syntheticReducer from './@movie-analyzer/synthetic_movie_detail_reducer';
import syntheticStateReducer from './@movie-analyzer/synthetic_movie_state_reducer';

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  initialAddReducer: initialAddReducer,
  userReducer: userReducer,
  verifyMovieReducer: verifyMovieReducer,
  movieStateReducer: movieStateReducer,
  movieDetailsReducer:movieDetailsReducer,
  castDetailsReducer:castDetailsReducer,
  spinnerReducer:spinnerReducer,
  syntheticReducer:syntheticReducer,
  syntheticStateReducer:syntheticStateReducer,
});

export default rootReducer
