import * as actionType from '../../actions/@movie-analyzer/actionType';

const initialState = {
  response_body:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STORE_VERIFY_MOVIE_DETAILS:
      return {
        ...state,
        response_body: action.value
      };
    case actionType.REMOVE_VERIFY_MOVIE_DETAILS:
      return {
        ...state,
        response_body:null
      };
    default:
      return state;
  }
};

export default reducer;
