import * as actionType from '../../actions/@movie-analyzer/actionType';

const initialState = {
  spinner: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TURN_ON_SPINNER:
      return {
        ...state,
        spinner: true
      };
    case actionType.TURN_OFF_SPINNER:
      return {
        ...state,
        spinner: false
      };
    default:
      return state;
  }
};

export default reducer;

