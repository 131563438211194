import * as actionType from "../../actions/@movie-analyzer/actionType";
import cloneDeep from "lodash/cloneDeep";

const initialState = {

  // movie details
   name : "",
   language : "",
   year : "",
   poster : "",
   description : "",
   description_less : "",
   genre : [],
   directors : [],
   producers : [],
   cast : [],
   release_date : "",
   awards : "0",
   nominations : "0",
   runtime : "",
   certificate : "",
   integration_graph : [],
   appearance_graph : [],
   word_cloud_data : [],
   genre_composition_chart : [],
   appearance_screentime_chart : [],
   rating : 0.0,
   star_rating : 0,
   social_r : 0.0,
   cast_r : 0.0,
   statistics_r : 0.0,
   composition_r : 0.0,
   social_r_progress : 0,
   cast_r_progress : 0,
   statistics_r_progress : 0,
   composition_r_progress : 0,
   social_reach : null,
   tagline1:"",
   tagline2:"",
   grp: null,
   premiered: true,
   score_weight:[],
   raw_cast:{},


};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_OUT_MOVIE_DETAILS:
      //---------------------------------------------------------------------
      let name = "";
      let language = "";
      let year = "";
      let poster = "";
      let description = "";
      let description_less = "";
      let genre = [];
      let directors = [];
      let producers = [];
      let cast = [];
      let release_date = "";
      let awards = "0";
      let nominations = "0";
      let runtime = "";
      let certificate = "";
      let integration_graph = [];
      let appearance_graph = [];
      let word_cloud_data = [];
      let genre_composition_chart = [];
      let appearance_screentime_chart = [];
      let rating = 0.0;
      let star_rating = 0;
      let social_r = 0.0;
      let cast_r = 0.0;
      let statistics_r = 0.0;
      let composition_r = 0.0;
      let social_r_progress = 0;
      let cast_r_progress = 0;
      let statistics_r_progress = 0;
      let composition_r_progress = 0;
      let social_reach = null;
      let tagline1="";
      let tagline2="";
      let score_weight=[];
      let raw_cast={};

      let md = cloneDeep(action.value);

      if(md !== null) {
        name = md.name;
        language = md.language;
        year = md.year;
        poster = md.poster_path;
        description = md.meta.desc;
        description_less = description.slice(0, 250) + "...";
        genre = md.meta.genre;
        directors = [];
        producers = [];
        cast = [];

        let director_string = md.meta.directors;
        if (director_string !== null) {
          Object.keys(director_string).map((e, i) => {
            let obj = {
              key: e,
              data: director_string[e]
            };
            directors.push(obj);
          });
        }

        let producer_string = null;
        if (producer_string !== null) {
          Object.keys(producer_string).map((e, i) => {
            let obj = {
              key: e,
              data: producer_string[e]
            };
            producers.push(obj);
          });
        }

        let cast_string = md.meta.cast;
        if (cast_string !== null) {
          Object.keys(cast_string).map((e, i) => {
            let obj = {
              key: e,
              data: cast_string[e]
            };
            cast.push(obj);
          });
        }
        raw_cast = cast_string;

        release_date = md.meta.release;
        if (md.meta.awardsSummary !== null) {
          awards = md.meta.awardsSummary.otherWinsCount;
          nominations = md.meta.awardsSummary.otherNominationsCount;
        }
        runtime = md.meta.runtime;
        certificate = md.meta.certificate;
        integration_graph = [];
        appearance_graph = [];

        let appearance_string = md.graphs.cast_appearance;
        if (appearance_string !== null && appearance_string !== undefined) {
          Object.keys(appearance_string).map((e, i) => {
            let dataTimeline = [];
            appearance_string[e].timeline.map((j, i) => {
              let obj = {
                x: " ",
                y: j
              };
              dataTimeline.push(obj);
            });
            let full_obj = {
              name: e,
              data: dataTimeline
            };
            let dataScreentime = {
              name: e,
              value: appearance_string[e].screentime
            };

            appearance_graph.push(full_obj);
            appearance_screentime_chart.push(dataScreentime);
          });
        }

        if(md.performance.stat != null){
          word_cloud_data = md.performance.stat.social.wordcloud;
          social_reach = md.performance.stat.social.reach_timeseries;
        }

        // rating = md.performance.stat.imdb.rating;
        rating = (md.performance.scores.overall).toFixed(1); //Math.round(number * 10) / 10
        let rating_x = parseFloat((md.performance.scores.overall).toFixed(1)); //Math.round(number * 10) / 10
        star_rating = Math.round(rating_x / 2);

        social_r = md.performance.scores.social !== undefined ? md.performance.scores.social : 0.0;
        cast_r = md.performance.scores.cast !== undefined ? md.performance.scores.cast : 0.0;
        statistics_r = md.performance.scores.statistics !== undefined ? md.performance.scores.statistics : 0.0;
        composition_r = md.performance.scores.composition !== undefined ? md.performance.scores.composition : 0.0;
        social_r_progress = social_r === "" ? 0 : social_r * 10;
        cast_r_progress = cast_r === "" ? 0 : cast_r * 10;
        statistics_r_progress = statistics_r === "" ? 0 : statistics_r * 10;
        composition_r_progress = composition_r === "" ? 0 : composition_r * 10;
        

        let tagline1 = md.meta.taglines;
        if (tagline1 !== null && tagline1 !== undefined) {
          tagline2 = tagline1.slice(0, 25) + "...";
        }

        integration_graph = md.graphs.interaction;
      }

      let grp = null;
      if(md.performance!==undefined && md.performance!==null && md.performance.grp!==undefined && md.performance.grp!==null) {
        grp = {
          value:md.performance.grp.value,
          time:md.performance.grp.time_of_day,
          day:md.performance.grp.day,
        }
      }

      let premiered = true;

      if(md.user_payload!==undefined && md.user_payload!==null) {
        premiered = md.user_payload.premiered;
      }

      let genre_composition = md.graphs.genre_composition;
      if (genre_composition !== undefined) {
        Object.keys(genre_composition).map((e, i) => {
          let obj = {
            name: e,
            y: Number((genre_composition[e]*100).toFixed(2))
          };
          genre_composition_chart.push(obj);
        });
      }

      let score_weight_data = md.performance.score_weights;
      if (score_weight_data !== undefined) {
        Object.keys(score_weight_data).map((e, i) => {
          let obj = {
            name: e,
            value: score_weight_data[e]
          };
          score_weight.push(obj);
        });
      }


      //---------------------------------------------------------------------


      return {
        ...state,
        name : name,
        language : language,
        year : year,
        poster : poster,
        description : description,
        description_less : description_less,
        genre : genre,
        directors : directors,
        producers : producers,
        cast : cast,
        release_date : release_date,
        awards : awards,
        nominations : nominations,
        runtime : runtime,
        certificate : certificate,
        integration_graph : integration_graph,
        appearance_graph : appearance_graph,
        word_cloud_data : word_cloud_data,
        genre_composition_chart : genre_composition_chart,
        appearance_screentime_chart : appearance_screentime_chart,
        rating : rating,
        star_rating : star_rating,
        social_r : social_r,
        cast_r : cast_r,
        statistics_r : statistics_r,
        composition_r : composition_r,
        social_r_progress : social_r_progress,
        cast_r_progress : cast_r_progress,
        statistics_r_progress : statistics_r_progress,
        composition_r_progress : composition_r_progress,
        social_reach : social_reach,
        tagline1:tagline1,
        tagline2:tagline2,
        grp: grp,
        premiered: premiered,
        score_weight:score_weight,
        raw_cast:raw_cast,
      };

    case actionType.REMOVE_MOVIE_DETAILS:
      return {...state,
        name : "",
        language : "",
        year : "",
        poster : "",
        description : "",
        description_less : "",
        genre : [],
        directors : [],
        producers : [],
        cast : [],
        release_date : "",
        awards : "0",
        nominations : "0",
        runtime : "",
        certificate : "",
        integration_graph : [],
        appearance_graph : [],
        word_cloud_data : [],
        genre_composition_chart : [],
        appearance_screentime_chart : [],
        rating : 0.0,
        star_rating : 0,
        social_r : 0.0,
        cast_r : 0.0,
        statistics_r : 0.0,
        composition_r : 0.0,
        social_r_progress : 0,
        cast_r_progress : 0,
        statistics_r_progress : 0,
        composition_r_progress : 0,
        social_reach : null,
        tagline1:"",
        tagline2:"",
        grp: null,
        premiered: true,
        score_weight:[],
        raw_cast:{},
      };

    case actionType.UPDATE_MOVIE_DETAILS:

      let updatedData = cloneDeep(action.value);


      let upd_rating=0.0;
      let upd_star_rating=0;
      let upd_score_weight=[];
      let upd_genre_composition_chart = [];
      let upd_integration_graph = [];
      let upd_appearance_screentime_chart = [];

      upd_rating =(updatedData.score.overall).toFixed(1);
      let rating_x = parseFloat((updatedData.score.overall).toFixed(1)); //Math.round(number * 10) / 10
      upd_star_rating = Math.round(rating_x / 2);

      let updated_score_weight = updatedData.score_weights;
      if (updated_score_weight !== null) {
        Object.keys(updated_score_weight).map((e, i) => {
          let obj = {
            name: e,
            value: updated_score_weight[e]
          };
          upd_score_weight.push(obj);
        });
      }

      let updated_genre_data = updatedData.genre_composition;
      if (updated_genre_data !== null) {
        Object.keys(updated_genre_data).map((e, i) => {
          let obj = {
            name: e,
            y: updated_genre_data[e]
          };
          upd_genre_composition_chart.push(obj);
        });
      }

      let updated_interation_data = updatedData.cast_interaction;
      if (updated_interation_data !== undefined && updated_interation_data.length !== 0){
        updated_interation_data.map(item=>{
          let obj={
            from:item._1,
            to:item._2,
            width:item.count
          };
          upd_integration_graph.push(obj);
        })
      }

      let updated_appearance_data = updatedData.cast_appearance;
      if (updated_appearance_data !== undefined && updated_appearance_data.length !== 0){
        Object.keys(updated_appearance_data).map((e, i) => {
          let obj = {
            name: e,
            value: updated_appearance_data[e]
          };
          upd_appearance_screentime_chart.push(obj);
        });
      }

      let cast_string = {};
      if (updatedData.cast_detail !== null && updatedData.cast_detail.length !== 0) {
        updatedData.cast_detail.map(item=>{
          cast_string[item.key]=item.data
        })
      }


      return {...state,
        rating : upd_rating,
        star_rating : upd_star_rating,
        social_r : updatedData.score.social,
        cast_r : updatedData.score.cast,
        statistics_r : updatedData.score.statistics,
        composition_r :updatedData.score.composition,
        social_r_progress : updatedData.score.social === "" ? 0 : updatedData.score.social * 10,
        cast_r_progress : updatedData.score.cast === "" ? 0 : updatedData.score.cast * 10,
        statistics_r_progress : updatedData.score.statistics === "" ? 0 : updatedData.score.statistics * 10,
        composition_r_progress : updatedData.score.composition === "" ? 0 : updatedData.score.composition * 10,
        score_weight : upd_score_weight,
        genre_composition_chart : upd_genre_composition_chart,
        integration_graph : upd_integration_graph,
        appearance_screentime_chart : upd_appearance_screentime_chart,
        cast : updatedData.cast_detail,
        raw_cast :cast_string,
      };

    default:
      return state;
  }
};

export default reducer;
