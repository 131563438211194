import * as actionTypes from './actionType';

export const storeResponseBody=(data)=>{
  return {
    type: actionTypes.STORE_VERIFY_MOVIE_DETAILS,
    value: data
  }
};

export const removeResponseBody=()=>{
  return {
    type: actionTypes.REMOVE_VERIFY_MOVIE_DETAILS,
    value: null
  }
};
