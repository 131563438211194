import * as actionTypes from './actionType';

export const storeRequestBody=(data)=>{
  return {
    type: actionTypes.STORE_REQUEST_BODY,
    value: data
  }
};

export const removeRequestBody=()=>{
  return {
    type: actionTypes.REMOVE_REQUEST_BODY,
    value: null
  }
};

export const storeCrewData=(data)=>{
  return {
    type: actionTypes.STORE_CREW_DATA,
    value: data
  }
};
