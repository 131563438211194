import axios from "axios";
import cofig from './apiConfig';
import Cookies from "js-cookie";
import * as cookie_data from "../utility/@movie-analyzer/cookies/index";

//css
import 'sweetalert2/src/sweetalert2.scss'
import * as ma_toasts from "../utility/@movie-analyzer/notifictions/toast";
import firebase from "firebase/compat/app";

export const callApi = async(apiObject) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization': Cookies.get(cookie_data.access_cookie)
  };
  let method = apiObject.method;
  let body = apiObject.body;
  const url = apiObject.endPoint;

  let result;

  await axios[method](`${cofig.serverUrl1}${cofig.basePath}${url}`, method !== 'get'? body : {headers: headers}, {headers: headers})
    .then(async response => {
      result = await {data:response.data, status: response.status};
    }).catch(async error => {

      if(error===undefined || error===null) {
        result = await {data:null, status: 404};
      } else if(error.response===undefined || error.response===null) {
        result = await {data:null, status: 404};
      } else if(error.response.status===undefined || error.response.status===null) {
        result = await {data:null, status: 404};
      } else {
        result = await {data:null, status: error.response.status};
      }

      // result = await {data:null, status: error.response.status};
    });


  return result;
};


export const callApix = async(apiObject) => {
  let headers = {
    'Content-Type':'application/json',
    'Authorization': Cookies.get(cookie_data.access_cookie)
  };
  let method = apiObject.method;
  let body = apiObject.body;

  const url = apiObject.endPoint;

  let result;

  await axios[method](`${cofig.serverUrl}${cofig.basePath}${url}`, method !== 'get'? body : {headers: headers}, {headers: headers})
    .then(async response => {
      result = await {data:response.data, status: response.status};
    }).catch(async error => {

      if(error===undefined || error===null) {
        result = await {data:null, status: 404};
      } else if(error.response===undefined || error.response===null) {
        result = await {data:null, status: 404};
      } else if(error.response.status===undefined || error.response.status===null) {
        result = await {data:null, status: 404};
      } else {
        result = await {data:null, status: error.response.status};
      }

      // result = await {data:null, status: error.response.status};
    });


  return result;
};

export const login = async(apiObject) => {
  let headers = {
    "accept": "application/json",
    'Content-Type':'application/x-www-form-urlencoded',
  };
  let method = apiObject.method;
  let body = apiObject.body;

  const url = apiObject.endPoint;

  let result;
    // ?grant_type=&username=${body.username}&password=${body.password}&scope=&client_id=&client_secret=
  let formdata = new FormData();
  formdata.append('grant_type','');
  formdata.append('username',body.username);
  formdata.append('password',body.password);
  formdata.append('scope','');
  formdata.append('client_id','');
  formdata.append('client_secret','');

  await axios[method](`${cofig.serverUrl}${cofig.basePath}${url}`, method !== 'get'? formdata : {headers: headers}, {headers: headers})
    .then(async response => {
      result = await {data:response.data, status: response.status};
    }).catch(async error => {

      if(error===undefined || error===null) {
        result = await {data:null, status: 404};
      } else if(error.response===undefined || error.response===null) {
        result = await {data:null, status: 404};
      } else if(error.response.status===undefined || error.response.status===null) {
        result = await {data:null, status: 404};
      } else {
        result = await {data:null, status: error.response.status};
      }

    });

  return result;
};



export const getPendingMovieProcess = async (job_id) => {
  if (!firebase.apps.length) {
    this.app = firebase.initializeApp(cofig.firebaseIDs);
  }else {
    this.app =firebase.app(); // if already initialized, use that one
  }
  this.database = this.app.database().ref().child(`movie-analyzer/${job_id}/progress`);
  this.database.on('value', snap => {
    let value = snap.val();
    if(value===null) {
      return 0;
    } else {
      return value;
    }
  });
  return 0;
};
