import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import toastr from 'toastr';
import "toastr/build/toastr.min.css"
import './ma-notification-style.scss'

export const maWarningNotifyCall = (heading, msg) => {
      toast.error(
        // <div>
        //   {/*<div className="toastify-header">*/}
        //   {/*  <div className="title-wrapper" style={{display:"flex", margin:"0 auto"}}>*/}
        //   {/*    <div className="avatar avatar-sm" style={{backgroundColor:'#FF1744'}}>*/}
        //   {/*        <span className="avatar-content">*/}
        //font   {/*          <svg*/}
        //   {/*            xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"*/}
        //   {/*            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">*/}
        //   {/*            <line x1="18" y1="6" x2="6" y2="18"></line>*/}
        //   {/*            <line x1="6" y1="6" x2="18" y2="18"></line>*/}
        //   {/*          </svg>*/}
        //   {/*      </span>*/}
        //   {/*    </div>*/}
        //   {/*    <h6 className="toast-title" style={{margin: 'auto 0', color:'#FF1744', fontWeight:"bold"}}>{heading}</h6></div>*/}
        //   {/*</div>*/}
        //   {/*<div className="toastify-body text-center"><span role="img" aria-label="toast-text">{msg}</span>*/}
        //   {/*</div>*/}
        // </div>,
        "Error"
        ,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose:1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
};

export const maErrorNotifyCall = (msg, type) => {
  notifyMessage(msg, type);
};

export const notifyMessage = (msg, type, duration) => {
  let msgType = "warning";

  if (type === 2) {
    msgType = "warning"
  }else if (type === 0) {
    msgType = "error"
  } else if (type === 1) {
    msgType = "success"
  }
  toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "500",
    "hideDuration": "500",
    "timeOut": duration === undefined ? "2000":duration,
    "extendedTimeOut": "500",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  };
  toastr[msgType](msg === undefined || msg === null ? "Please enter correct details" : msg , type === 0 ? 'Error':type === 1 ? 'Success':'Warning')
};
