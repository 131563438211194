import * as actionTypes from './actionType';

export const turnOnSpinner=()=>{
  return {
    type: actionTypes.TURN_ON_SPINNER,
    value: true
  }
};

export const turnOffSpinner=()=>{
  return {
    type: actionTypes.TURN_OFF_SPINNER,
    value: false
  }
};
