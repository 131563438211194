import React from "react"
import { Navbar} from "reactstrap"
import classnames from "classnames"
// import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import Malogo from '../../../assets/img/logo/temp2.png';
import {withRouter} from "react-router-dom";
import * as actionCreator from "../../../redux/actions/@movie-analyzer/actionCreator";
import connect from "react-redux/es/connect/connect";
import Swal from 'sweetalert2/dist/sweetalert2.js'

// const ThemeNavbar = props => {

const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"];
const navbarTypes = ["floating" , "static" , "sticky" , "hidden"];

class ThemeNavbar extends React.Component{

  maConformMessage = () => {

    if(this.props.synthetic_data !==null) {
      Swal.fire({
        title: 'Are you sure you want to leave this page?',
        text: 'Changes that you made may not be saved.',
        icon: 'question',
        confirmButtonText: 'Leave page',
        cancelButtonText:'Stay on page',
        showCloseButton: true,
        showCancelButton: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.props.store_synthetic_data(null);
          this.props.history.push("/")
        }
      })
    }else{
      this.props.history.push("/")
    }

  };

  render() {

    let props = this.props;

    return (
      <React.Fragment>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
              "navbar-dark": colorsArr.includes(props.navbarColor),
              "bg-primary":
                props.navbarColor === "primary" && props.navbarType !== "static",
              "bg-danger":
                props.navbarColor === "danger" && props.navbarType !== "static",
              "bg-success":
                props.navbarColor === "success" && props.navbarType !== "static",
              "bg-info":
                props.navbarColor === "info" && props.navbarType !== "static",
              "bg-warning":
                props.navbarColor === "warning" && props.navbarType !== "static",
              "bg-dark":
                props.navbarColor === "dark" && props.navbarType !== "static",
              "d-none": props.navbarType === "hidden" && !props.horizontal,
              "floating-nav":
                (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
              "navbar-static-top":
                props.navbarType === "static" && !props.horizontal,
              "fixed-top": props.navbarType === "sticky" || props.horizontal,
              "scrolling": props.horizontal && props.scrolling

            }
          )}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <div className="bookmark-wrapper">
                  {/*----------------logo-----------------*/}
                  {/*<div className="brand-logo mr-50"></div>*/}
                  <img src={Malogo} alt="" style={{width: '350px',
                    marginLeft: '0%', cursor:'pointer'}} onClick={()=> this.maConformMessage()}/>
                  {/*<NavbarBookmarks*/}
                  {/*  sidebarVisibility={props.sidebarVisibility}*/}
                  {/*  handleAppOverlay={props.handleAppOverlay}*/}
                  {/*/>*/}
                </div>
                {props.horizontal ? (
                  <div className="logo d-flex align-items-center">
                    {/*<div className="brand-logo mr-50"></div>*/}
                    {/*<h2 className="text-primary brand-text mb-0">Vuexy</h2>*/}
                  </div>
                ) : null}
                <NavbarUser
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  userName="John Doe"
                  userImg={ userImg }
                />
              </div>
            </div>
          </div>
        </Navbar>
      </React.Fragment>
    )
  }


};


const mapStateToProps = (state) => ({
  synthetic_data: state.syntheticReducer.synthetic_data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    store_synthetic_data: (data)=> dispatch(actionCreator.storeSyntheticMovieDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ThemeNavbar));
