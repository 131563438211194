import * as actionTypes from './actionType';

export const storeUser=(data)=>{
  return {
    type: actionTypes.STORE_USER,
    value: data
  }
};

export const removeUser=()=>{
  return {
    type: actionTypes.REMOVE_USER,
    value: null
  }
};
