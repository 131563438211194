import * as actionTypes from './actionType';

export const storeCastDetails=(data)=>{
  return {
    type: actionTypes.SET_OUT_CAST_DETAILS,
    value: data
  }
};

export const removeCastDetails=()=>{
  return {
    type: actionTypes.REMOVE_CAST_DETAILS,
    value: null
  }
};
