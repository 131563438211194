export const STORE_REQUEST_BODY = "STORE_REQUEST_BODY";
export const REMOVE_REQUEST_BODY = "REMOVE_REQUEST_BODY";
export const STORE_CREW_DATA = "STORE_CREW_DATA";
export const STORE_VERIFY_MOVIE_DETAILS = "STORE_VERIFY_MOVIE_DETAILS";
export const REMOVE_VERIFY_MOVIE_DETAILS = "REMOVE_VERIFY_MOVIE_DETAILS";
export const STORE_MOVIE_STATE = "STORE_MOVIE_STATE";
export const REMOVE_MOVIE_STATE = "REMOVE_MOVIE_STATE";
export const STORE_USER = "STORE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const SET_OUT_MOVIE_DETAILS = "SET_OUT_MOVIE_DETAILS";
export const REMOVE_MOVIE_DETAILS = "REMOVE_MOVIE_DETAILS";
export const SET_OUT_CAST_DETAILS = "SET_OUT_CAST_DETAILS";
export const REMOVE_CAST_DETAILS = "REMOVE_CAST_DETAILS";
export const TURN_ON_SPINNER = "TURN_ON_SPINNER";
export const TURN_OFF_SPINNER = "TURN_OFF_SPINNER";
export const UPDATE_MOVIE_DETAILS = "UPDATE_MOVIE_DETAILS";
export const SET_SYNTHETIC_MOVIE_DETAILS = "SET_SYNTHETIC_MOVIE_DETAILS";
export const ON_SYNTHETIC_STATE = "ON_SYNTHETIC_STATE";
export const OFF_SYNTHETIC_STATE = "OFF_SYNTHETIC_STATE";
export const STORE_NEW_SIMILAR_MOVIE = "STORE_NEW_SIMILAR_MOVIE";
