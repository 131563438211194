/**
 * Created DateTime: 2021-01-28 00:03:00
 * URL_TESTING = Testing Server URL
 * URL_PROD = Production Server URL
 * @type {string}
 */
  //const URL_TESTING1 = "http://test-local-dev.chiefdx-dev.com";
  //const URL_TESTING1 = "http://123.231.95.165:5000";
  //const URL_TESTING1 = "http://123.231.95.165:32080";
  const URL_TESTING1 = "https://api-csp.chiefdx-dev.com";
  //const URL_TESTING = "https://api-content-selection.chiefdx-dev.com";
//   const URL_TESTING = "http://123.231.95.165:30000";
// const URL_TESTING = "http://123.231.21.217:30000";
const URL_PROD = "";
const conf = {
  webUrl:'https://contentselectionx.chiefdx-dev.com',
  serverUrl1: URL_TESTING1,
  serverUrl: URL_TESTING1,
  // serverUrl: window.location.hostname.indexOf('localhost') !== -1 ||
  // window.location.hostname.indexOf('localhost:3') !== -1 ? URL_TESTING : URL_PROD,
  basePath: ``,
  firebaseIDs: {
    apiKey: "AIzaSyAMXSBgOM2906eK840wYuUr9KZ9f-HdhzM",
    authDomain: "successpredictionprogress.firebaseapp.com",
    databaseURL: "https://successpredictionprogress-default-rtdb.firebaseio.com",
    projectId: "successpredictionprogress",
    storageBucket: "successpredictionprogress.appspot.com",
    messagingSenderId: "343381141406",
    appId: "1:343381141406:web:4a961c78b9cdf2a902588f",
    measurementId: "G-190DDRQ5R5"
  },
};
export default conf;
