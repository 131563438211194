import React from "react";
import {Button} from "reactstrap";

// style files
import './ma-commonbtn-style.scss'

class CommonBtn extends React.Component{
  render() {
    let commonbtn_name =  this.props.name;
    let commonbtn_function = this.props.function;
    // let commonbtn_color = this.props.color;

    return(
      <div className={"ma-commonbtn"}>
        <Button className={"commonbtn"} onClick={commonbtn_function}>
          {(commonbtn_name===undefined || commonbtn_name===null)?"Click Me":commonbtn_name}
        </Button>
      </div>
    );
  }
}

export default CommonBtn;
