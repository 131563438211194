import * as actionType from '../../actions/@movie-analyzer/actionType';

const initialState = {
  id: -1
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STORE_MOVIE_STATE:
      return {
        ...state,
        id: action.value
      };
    case actionType.REMOVE_MOVIE_STATE:
      return {
        ...state,
        id:-1
      };
    default:
      return state;
  }
};

export default reducer;
